import contactUsImage from '../assets/Home/ContactUs.svg';
import { LOCALES } from '../ii8n/locales';
import { useLocation } from 'react-router-dom';
const Constants = {
  // siteKey: '6LcCZxMlAAAAAE7n1LTk33YzcHvDy6hGUWYBKkON',
  // secretKey: '6LcCZxMlAAAAAKywTPxKeMdNtDGgndSWs5p4TqOm',
  siteKey: '6LdCAp4lAAAAAGslxE_jJ5JdcnKNzFWOHj8qI8QP',
  secretKey: '6LdCAp4lAAAAADEzK8LTNKE9Ly98wbD3qSSNqa6Z'
}

const routePath = {
  [LOCALES.ENGLISH]: {
    home: 'en',
    service: 'services',
    servicesWebsite: 'services/',
    'about-us': 'about-us',
    work: 'work',
    career: 'careers',
    contact: 'contact-us',
    blog: 'blog',
    'case-study': 'work',
    'privacy-policy': 'privacy-policy',
    'legal-notice': 'legal-notice',
    media: 'media'
  },
  [LOCALES.GERMAN]: {
    home: 'de',
    service: 'dienstleistungen',
    servicesWebsite: 'dienstleistungen/',
    'about-us': 'ueber-uns',
    work: 'arbeit',
    career: 'Karriere',
    contact: 'Kontakt',
    blog: 'blog-german',
    'case-study': 'arbeit',
    'privacy-policy': 'Datenschutzerklärung',
    'legal-notice': 'Impressum',
    media: 'Medien'
  }
}

const getRoutePath = (path) => {
  const locale = localStorage.getItem('locale') || LOCALES.ENGLISH;
  return `/${routePath[locale][path]}`;
}
const getPageURl = (path = '', locale) => {
  path = decodeURI(path);
  let mPath = '';
  let eIdx = 0;
  for (let j = 1; j < path.length; j++) {
    if (path[j] === '/') {
      break;
    }
    eIdx = j;
    mPath += path[j];
  }
  let fPath = '';
  const langVal = Object.values(routePath)
  for (let i = 0; i < langVal.length; i++) {
    const pageIdx = Object.values(langVal[i]).indexOf(mPath);
    if (pageIdx > -1) {
      const page = Object.keys(langVal[i])[pageIdx];
      fPath = routePath[locale][page];
      break;
    }
  }
  return fPath ? path.replace(mPath, fPath) : '/';
}

const getPathLocale = (path) => {
  path = decodeURI(path);
  let mPath = '';
  let eIdx = 0;
  for (let j = 1; j < path.length; j++) {
    if (path[j] === '/') {
      break;
    }
    eIdx = j;
    mPath += path[j];
  }
  let result = -1;
  const langVal = Object.values(routePath);
  for (let i = 0; i < langVal.length; i++) {
    const pageIdx = Object.values(langVal[i]).indexOf(mPath);
    if (pageIdx > -1) {
      result = i;
      break;
    }    
  }
  return result;
}

// const numberAndSpecialCharater = (val) => {
//     return /([0-9%@#$&*()\\{}\/[\]<>?=+])+/g.test(val);
// }
const specialCharaterRegex = (val) => {
  return /([%@#$&*()\\{}\/[\]<>?=+])+/g.test(val);
}
const isValidFullName = (firstName) => {
  // return !numberAndSpecialCharater(firstName) 
  // return /^[A-Za-z!\u00C0-\u017F]+$/.test(firstName);
  // return /[^0-9,@#$%*{}()\[\]<>?\/=+]+/.test(firstName);
  return /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g.test(firstName);
}

const isValid = (field) => {
  return !specialCharaterRegex(field);
  // return /^[@#$%*{}()\[\]<>?\/=+]*$/.test(field);
}

const specialCharaterLnRegex = (val) => {
  return /([%@#$&*()\\{}[\]<>?=+])+/g.test(val);
}
const isValidLnAndXing = (field) => {
  return !specialCharaterLnRegex(field);
  // return /^[@#$%*{}()\[\]<>?\/=+]*$/.test(field);
}
const isValidPhoneNumber = (phoneNumber) => {
  return /^[d()0-9+()-\s]+$/.test(phoneNumber);
}
const isNumberOnly = (phoneNumber) => {
  return /^[0-9]+$/.test(phoneNumber);
}
const isValidEmail = (email) => {
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  // return /\S+@\S+\.\S+/.test(email);s
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
}

const ActionTypesList = {
  'SUCCESS_CLIENTS_INFO': 'SUCCESS_CLIENTS_INFO',
  'CREATE_CONTACT_RESPONSE': 'CREATE_CONTACT_RESPONSE',
  'CREATE_CAREER_RESPONSE': 'CREATE_CAREER_RESPONSE',
  'SUCCESS_FETCH_SERVICE_LIST': 'SUCCESS_FETCH_SERVICE_LIST',
  'SUCCESS_FETCH_SERVICE_DETAILS': 'SUCCESS_FETCH_SERVICE_DETAILS',
  'SUCCESS_FETCH_PARTNERS': 'SUCCESS_FETCH_PARTNERS',
  'SUCCESS_FETCH_JOB_INFO': 'SUCCESS_FETCH_JOB_INFO',
  'SUCCESS_FETCH_JOB_LIST': 'SUCCESS_FETCH_JOB_LIST',
  'JOB_APPLY_RESPONSE': 'JOB_APPLY_RESPONSE',
  'HOME_ACTIVE_WORK_LIST_REQUEST': 'HOME_ACTIVE_WORK_LIST_REQUEST',
  'HOME_ACTIVE_WORK_LIST_SUCCESS': 'HOME_ACTIVE_WORK_LIST_SUCCESS',
  'HOME_ACTIVE_WORK_LIST_FAILED': 'HOME_ACTIVE_WORK_LIST_FAILED',
  'ALL_ACTIVE_WORKS_LIST_REQUEST': 'ALL_ACTIVE_WORKS_LIST_REQUEST',
  'ALL_ACTIVE_WORKS_LIST_SUCCESS': 'ALL_ACTIVE_WORKS_LIST_SUCCESS',
  'ALL_ACTIVE_WORKS_LIST_FAILED': 'ALL_ACTIVE_WORKS_LIST_FAILED',
  'WORK_LIST_BY_SERVICEID_FETCH_REQUEST': 'WORK_LIST_BY_SERVICEID_FETCH_REQUEST',
  'WORK_LIST_BY_SERVICEID_REQUEST_SUCCESS': 'WORK_LIST_BY_SERVICEID_REQUEST_SUCCESS',
  'WORK_LIST_BY_SERVICEID_LIST_REQUEST_FAILED': 'WORK_LIST_BY_SERVICEID_LIST_REQUEST_FAILED',
  'SUBSCRIBE_RESPONSE': 'SUBSCRIBE_RESPONSE',
  'LANGUAGE_RESPONSE': 'LANGUAGE_RESPONSE',
  'WORK_CHECK_RESPONSE': 'WORK_CHECK_RESPONSE',
  'BLOG_GET_ACTIVE_HOME_LIST_REQUEST': 'BLOG_GET_ACTIVE_HOME_LIST_REQUEST',
  'BLOG_GET_ACTIVE_HOME_LIST_SUCCESS': 'BLOG_GET_ACTIVE_HOME_LIST_SUCCESS',
  'BLOG_GET_ACTIVE_HOME_LIST_FAILED': 'BLOG_GET_ACTIVE_HOME_LIST_FAILED',
  'FETCH_HOME_BLOGS': 'FETCH_HOME_BLOGS',
  'FETCH_CS_DETAILS': 'FETCH_CS_DETAILS',
}

const DefaultCTA = {
  title: `Let's create <br />
    something amazing together.`,
  description: `Tell us about your project. <br /> We'll be happy to help`,
  btnText: 'CONNECT',
  btnLink: 'contact'
}

const DefaultCTAUpdated = {
  title: `default_cta_title`,
  description: `default_cta_desc`,
  btnText: 'default_cta_btntext',
  btnLink: 'contact'
}

const DefaultCTAUpdatedV2 = {
  title: `default_cta_v2_title`,
  description: 'default_cta_v2_desc',
  btnText: 'default_cta_v2_btntext',
  btnLink: 'contact'
}

const MainCTA = {
  title: `main_cta_title`,
  description: `main_cta_desc`, // #11
  image: contactUsImage,
  btnText: 'main_cta_btn',
  btnLink: 'contact',
  titleMb: '',
  btnText2: 'main_cta_btn_text',
}

const CaseStudyCTA = {
  title: `cs_cta_title`,
  description: `cs_cta_desc`,
  image: contactUsImage,
  btnText: 'cs_cta_btntext',
  btnLink: 'contact',
  titleMb: `default_cta_title`,
}

const CaseStudyCTAUpdated = {
  title: `cs_cta_title`,
  description: `cs_cta_desc`,
  btnText: 'cs_cta_btntext',
  btnLink: 'contact',
  titleMb: `default_cta_title`
}

const ContactCTA = {
  title: `contact_cta_title`,
  description: `contact_cta_desc`,
  btnText: 'contact_cta_btntext',
  // title: `See how we can help you`,
  // description: `Check out how your business can benefit from our services<br /> and how we helped others earlier`,
  // btnText: 'go to homepage',
  btnLink: 'home'
}

const urlCreate = (data) => {
  return data.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-');
}

const baseUrl = 'https://skiy.skiy31web.de/skiy/';
const lang = () => localStorage.getItem('localeCode');
const cleverUrl = 'https://cleverstaff.net/hr/public/'
const host = '';
const deviceType = {
  Desktop: 'd1',
  Mobile: 'd2'
}
const getDeviceType = () => screenSize() !== 'xs' ? deviceType.Desktop : deviceType.Mobile;
const apiURls = {
  csDetail: (id) => `${baseUrl}api/${lang()}/${getDeviceType()}/ourwork/details/${id}`,
  servicelist: () => `${baseUrl}api/${lang()}/${getDeviceType()}/category/active-list`,

  serviceDetail: (id) => `${baseUrl}api/${lang()}/${getDeviceType()}/category/details/${id}`,
  vacancyDetail: (id) => `${cleverUrl}getVacancy?host=${host}&id=${id}`,
  vacancyList: () => `${cleverUrl}getVacancies?alias=skiy31-gmbh`,
  partners: () => `${baseUrl}api/${lang()}/${getDeviceType()}/clients/active-list`,
  testiMonials: () => `${baseUrl}api/${lang()}/${getDeviceType()}/testimonials/active-list`,
  clients: () => `${baseUrl}api/${lang()}/${getDeviceType()}/clients/home-active-list`,
  jobList: () => `${baseUrl}api/job/list`,
  jobDetail: (id) => `${baseUrl}api/job/detail?id=${id}`,
  applyJob: () => `${baseUrl}api/${lang()}/job/send-job-application`,
  subscribe: () => `${baseUrl}api/${lang()}/${getDeviceType()}/newsletter`,
  workCheck: () => `${baseUrl}api/checkWork`,
  workService: () => `${baseUrl}api/checkService`,
  registerContact: () => `${baseUrl}api/${lang()}/${getDeviceType()}/contactus/store`,
  createCareer: () => `${baseUrl}api/${lang()}/job/send-application`,
  languages: () => `${baseUrl}api/languages/active-list`,
  homeBlogs: () => `${baseUrl}api/${lang()}/${getDeviceType()}/blogs/home-active-list`,
  getBaseOurWorklUrl: () => `api/${lang()}/${getDeviceType()}/ourwork/`,
  getBaseBlogUrl: () => `api/${lang()}/${getDeviceType()}/blogs/`
}

const checkLanguage = () => {
  if (window.location.pathname.indexOf('dienstleistungen') == -1 && window.location.pathname.indexOf('service') === -1 && window.location.pathname.indexOf('work') === -1 && window.location.pathname.indexOf('arbeit') === -1) {
    let index = getPathLocale(window.location.pathname);
    if (index > -1) {
      let lang = index == 0 ? 'l3' : 'l2';
      if (!localStorage.getItem('locale')) {
        if (window.location.pathname == "/") {
          let browserLang = navigator.language || navigator.userLanguage;
          lang = browserLang.split("-")[0] == 'de' ? 'l2' : 'l3';
        }
      }
      setLang(lang);
    }
    else {
      if (!localStorage.getItem('locale')) {
        if (window.location.pathname == "/") {
          let browserLang = navigator.language || navigator.userLanguage;
          let lang = browserLang.split("-")[0] == 'de' ? 'l2' : 'l3';
          setLang(lang);
        }
      }
    }
  }
  else if (window.location.pathname.indexOf('work') !== -1 || window.location.pathname.indexOf('arbeit') !== -1) {
    let lang = window.location.pathname.indexOf('work') !== -1 ? 'l3' : 'l2';
    if (!localStorage.getItem('localeCode') || localStorage.getItem('localeCode') != lang) {
      setLang(lang);
      if (document.getElementById(lang)) {
        document.getElementById(lang).click();
      }
    }
  }
  else if (window.location.pathname.indexOf('services') !== -1 || window.location.pathname.indexOf('dienstleistungen') !== -1) {
    let lang = window.location.pathname.indexOf('services') !== -1 ? 'l3' : 'l2';
    if (!localStorage.getItem('localeCode') || localStorage.getItem('localeCode') != lang) {
      setLang(lang);
      if (document.getElementById(lang)) {
        document.getElementById(lang).click();
      }
    }
  }
}

const setLang = (lang) => 
{
  localStorage.setItem('localeCode', lang)
  localStorage.setItem('locale', lang == 'l3' ? 'en' : 'de')
}

const ActiveTypesMapping = {
  ALL: 'All',
  'remote': 'remote',
  'underemployment': 'underemployment',
  'fullEmployment': 'fullEmployment'
}

const footerServicesMenu = [
  {
    name: 'UI/UX',
    link: 'ui-ux-design'
  },
  {
    name: 'Branding',
    link: '/branding'
  },
  {
    name: 'Remote teams',
    link: '/remote-teams'
  },
  {
    name: 'Website',
    link: '/website'
  },
  {
    name: 'Mobile App',
    link: '/mobile'
  },
  {
    name: 'Web App',
    link: '/web'
  },
  {
    name: 'e-commerce',
    link: '/e-commerce'
  }

]

const footers = [
  {
    name: '',
    key: 'main',
    clsName: 'main-footer-link',
    description: ['Team', 'History', 'Contact us', 'Locations'],
    menus: [
      {
        name: 'Home',
        link: 'home',
        key: 'footer_home_text'
      },
      {
        name: 'About Us',
        link: 'about-us',
        key: 'footer_about_text'
      },
      {
        name: 'Services',
        link: 'servicesWebsite',
        key: 'footer_service_text'
      },
      {
        name: 'Work',
        link: 'work',
        key: 'footer_work_text'
      },
      {
        name: 'Careers',
        link: 'career',
        key: 'footer_career_text'
      },
      {
        name: 'Blog',
        link: 'blog',
        key: 'footer_blog_text'
      },
      {
        name: 'Contact Us',
        link: 'contact',
        key: 'footer_contact_text'
      }
    ]
  },
  {
    name: 'Services',
    key: 'footer_service_text',
    clsTitleName: 'main-footer-link',
    clsName: 'sub-main-footer-link',
    menus: footerServicesMenu
  },
  {
    name: 'Offices',
    key: 'footer_office_text',
    clsTitleName: 'main-footer-link',
    clsName: 'sub-main-footer-link',
    menus: [
      {
        name: 'DE - Mannheim',
        link: 'contact',
        key: 'footer_office_dem_text'
      },
      {
        name: 'DE - Deidesheim',
        link: 'contact',
        key: 'footer_office_ded_text'
      },
      {
        name: 'UA - Lviv',
        link: 'contact',
        key: 'footer_office_ual_text'
      },
      {
        name: 'IN - Coimbatore',
        link: 'contact',
        key: 'footer_office_cb_text'
      }
    ]
  },
  {
    name: 'Legal',
    key: 'footer_legal_text',
    clsTitleName: 'main-footer-link',
    clsName: 'sub-main-footer-link',
    menus: [
      {
        name: 'Legal Notice',
        link: 'legal-notice',
        key: 'footer_legal_notice_text'
      },
      {
        name: 'Privacy Policy',
        link: 'privacy-policy',
        key: 'footer_privacy_policy_text'
      }
    ]
  },
  {
    name: 'What we were up to',
    key: 'footer_what_we_are_text',
    type: 'content',
    clsTitleName: 'main-footer-content',
    description: ['Privacy policy', 'Terms of use'],
    data: [{
      title: 'Our founder’s stories',
      link: 'https://www.linkedin.com/in/konstantin-shcherbina/',
      key: 'footer_founder_text'
    }, {
      title: 'Our company’s updates',
      link: 'https://www.linkedin.com/company/skiy31-gmbh/',
      key: 'footer_company_update_text'
    }],
    menus: []
  },
];

const navToService = (pData, sData) => {
  let link = `${pData ? pData : sData}`;
  link = urlCreate(link);
  return `${getRoutePath('service')}/${link}`;
}

const mapPosLocation = {
  "fullEmployment": "Full Time",
  "underemployment": "Part Time",
  "remote": "Remote",
  "trainingAndPractice": "Internship, Practice",
  "projectWork": "Project",
  "temporaryWork": "Temporary",
  "relocate": "Relocate"
}

const mapYears = {

  e00_no_experience: 'No Experience',
  e01_less_than1year: "Less than 1 year",
  e1_1year: "1 Year",
  e2_2years: "2 Years",
  e3_3years: "3 Years",
  e4_4years: "4 Years",
  e5_5years: "5+ Years",
  e6_10years: "10+ Years",

};
const mapLevel = {
  Upper_Intermediate: 'Upper Intermediate',
  Intermediate: 'Intermediate'
}

const servicesCommonConfig = {
  mainCTA: {
    title: `Make your business <br />
            benefit`,
    description: `Contact us to understand if we are a match. We will provide you with all details.`,
    image: contactUsImage,
    btnText: 'GET STARTED',
    btnLink: 'contact'
  },
  finalCTA: {
    title: `final_cta_title`,
    description: `final_cta_desc`,
    btnText: 'final_cta_btntext',
    btnLink: 'contact'
  },
}

const screenSize = () => {
  const scrWid = window.screen.width;
  if (scrWid > 1500)
    return 'lg'
  if (scrWid > 1200 && scrWid < 1500)
    return 'lgs'
  if (scrWid > 900 && scrWid <= 1200)
    return 'md'
  if (scrWid > 641 && scrWid <= 899)
    return 'sm'
  return 'xs';

}
const scrollHeaderHeight = () => {
  const ssize = screenSize();
  let scrollHeight = 100;
  if (ssize === 'xs') {
    scrollHeight = 40;
  }
  return scrollHeight;
}
const TiltDefaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 5, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1, // 2 = 200%, 1.5 = 150%, etc..
  speed: 400, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)",
};

const PrivacyPolicyDetailsEN = {
  data: [
    {
      "heading": "Privacy Policy",
      "data": [
        {
          "heading": "",
          "data": [
            {
              "heading": "Privacy",
              "details": "<span class='mobile-paragraph'>The operator of this website takes the protection of your personal data very seriously. We treat your personal data confidentially and following the statutory data protection regulations and this data protection declaration.</span> <br/><span class='mobile-paragraph'>You can use our website without providing personal data. Insofar as personal data (e.g. name, address, or email address) is collected on our site, this is always done voluntarily as far as possible. This data will not be passed on to third parties without your express consent.</span><br/>We would like to point out that data transmission over the Internet (e.g. when communicating by email) can have security gaps. It is not possible to completely protect data from access by third parties."
            },
            {
              "heading": "Revocation of your consent to data processing",
              "details": "<span class='mobile-paragraph'>Many data processing operations are only possible with your express consent. You can withdraw your consent at any time. All you need to do is send an informal email to us. </span> The legality of the data processing carried out before the revocation remains unaffected by the revocation."
            },
            {
              "heading": "Right to complain with the competent supervisory authority",
              "details": "<span class='mobile-paragraph'>In the event of violations of data protection law, the person concerned has the right to complain with the responsible supervisory authority. The competent supervisory authority for data protection issues is the state data protection officer of the federal state in which our company is based.</span> <br/><span class='mobile-paragraph'>Please find the list of data protection officers and their contact details at the following link:</span> <a target='blank' href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>"
            },
            {
              "heading": "Information, blocking, deletion",
              "details": "<span class='mobile-paragraph'>Within the framework of the applicable legal provisions, you have the right to retrieve information about your stored personal data, its origin and recipient and the purpose of the data processing and, if necessary, the right to correct, block or delete this data.</span> You can contact us at any time at the address given in the imprint if you have any further questions about personal data."
            },
            {
              "heading": "Objection to advertising emails",
              "details": "We herein object to the use of contact data published within the scope of this website for sending unsolicited advertising and information material. The operator of the website expressly reserves the right to take legal action if unsolicited advertising information is sent, such as spam emails."
            },
            {
              "heading": "Data protection officer required by law",
              "details": "We have appointed data protection officers for our company.<br/>Konstantin Shcherbina <br/>Weinstraße 5 <br/>67146 Deidesheim  <br/>Phone: +49 (0) 6234 809 2676 <br/>Email: datenschutz@skiy31.de"
            },
          ]
        },
        {
          "heading": "Plugins and Tools",
          "data": [
            {
              "heading": "Google Maps",
              "details": "<span class='mobile-paragraph'>This site uses the Google Maps map service via an API. The provider is Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA. Your IP address is saved and transferred to a Google server in the USA and stored there to use the map service. The provider of this page does not influence this data transmission. </span> <span class='mobile-paragraph'> The use of Google Maps is in the interest of an appealing presentation of our online offers and to make it easier to find the places we have indicated on the website. This represents a legitimate interest within the meaning of Art. 6 Para. 1 lit. f GDPR. Please find more information on the handling of user data in the privacy policy or Google:</span><br/> <a target='blank' href='https://www.google.de/intl/de/policies/privacy/'>https://www.google.de/intl/de/policies/privacy/</a>"
            },
            {
              "heading": "YouTube",
              "details": "<span class='mobile-paragraph'>We have turned on \"Privacy-Enhanced Mode\" for YouTube videos displayed on the Website. This means that YouTube does not place cookies or track viewing behavior for advertising purposes. The integration does not embed any YouTube videos that are not part of our content. We disabled certain functionality on YouTube (called \"rel\" functionality), so that content outside of our content is not played. For more information, please see </span><a href='http://www.google.com/policies/privacy/partners' target='_blank'>Google's privacy practices.</a>"
            }
          ]
        },
      ]
    },
    {
      "heading": "Cookie Policy",
      "data": [
        {
          "heading": "",
          "data": [
            {
              "heading": "About this cookie policy.",
              "details": "<span class='mobile-paragraph'>This Cookie Policy explains what cookies are and how we use them. You should read this policy to understand what cookies are, how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used and how to control the cookie preferences. For further information on how we use, store and keep your personal data secure, see our Privacy Policy.</span><br/>You can at any time change or withdraw your consent from the Cookie Declaration on our website. Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy. Your consent applies to the following domains: skiy31.de ."
            },
            {
              "heading": "What are cookies ?",
              "details": "<span class='mobile-paragraph'>Cookies are small text files that are used to store small pieces of information. The cookies are stored on your device when the website is loaded on your browser.</span> These cookies help us make the website function properly, make the website more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement."
            },
            {
              "heading": "How do we use cookies ?",
              "details": "<span class='mobile-paragraph'>As most of the online services, our website uses cookies first-party and third-party cookies for a number of purposes. The first-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</span><br/>The third-party cookies used on our websites are used mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website."
            },
            {
              "heading": "What types of cookies do we use ?",
              "details": "",
              "data": [
                {
                  "heading": "Necessary",
                  "details": "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.",
                  "tabledata": [
                    {
                      "heading": "viewed_cookie_policy",
                      "details": "The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data."
                    },
                    {
                      "heading": "cookielawinfo-checkbox-necessary",
                      "details": `This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category "Necessary".`
                    },
                    {
                      "heading": "cookielawinfo-checkbox-non-necessary",
                      "details": `This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category "Non Necessary".`
                    }
                  ]
                },
                {
                  "heading": "Analytics",
                  "details": "Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc.",
                  "tabledata": [
                    {
                      "heading": "_gat_gtag_UA_171995910_1",
                      "details": "Google uses this cookie to distinguish users."
                    },
                    {
                      "heading": "_ga",
                      "details": `This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assigns a randomly generated number to identify unique visitors.`
                    },
                    {
                      "heading": "_gid",
                      "details": `This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in creating an analytics report of how the wbsite is doing. The data collected including the number visitors, the source where they have come from, and the pages viisted in an anonymous form.`
                    }
                  ]
                },
                {
                  "heading": "Advertisement",
                  "details": "Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These cookies track visitors across websites and collect information to provide customized ads.<br/>The below list details the cookies used in our website.",
                  "tabledata": [
                    {
                      "heading": "NID",
                      "details": "This cookie is used for the user profile to display personalized ads."
                    }
                  ]
                },
              ]
            },
            {
              "heading": "How can I control the cookie preferences?",
              "className": 'control-cookie-pref',
              "details": "You can manage your cookies preferences by clicking on the “Settings” button and enabling or disabling the cookie categories on the popup according to your preferences.<br/>Should you decide to change your preferences later through your browsing session, you can click on link below:<br/>Your current state: Consent Accepted. Allow all cookies (Necessary, Analytics, Advertisement). Manage your consent.<br/>This will display the consent notice again enabling you to change your preferences or withdraw your consent entirely.<br/>In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. To find out more on how to manage and delete cookies, visit wikipedia.org, www.allaboutcookies.org."
            }
          ]
        }
      ]
    }
  ]
}

const PrivacyPolicyDetailsDE = {
  data: [
    {
      "heading": "Datenschutzbestimmungen",
      "data": [
        {
          "heading": "",
          "data": [
            {
              "heading": "Datenschutz",
              "details": "<span class='mobile-paragraph'>Der Betreiber dieser Website nimmt den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</span> <br/><span class='mobile-paragraph'>Sie können unsere Website nutzen, ohne personenbezogene Daten anzugeben. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</span><br/>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich."
            },
            {
              "heading": "Widerruf Ihrer Einwilligung zur Datenverarbeitung",
              "details": "<span class='mobile-paragraph'>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können Ihre Einwilligung jederzeit widerrufen. Dazu genügt eine formlose E-Mail an uns.</span> Die Rechtmäßigkeit der vor dem Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt."
            },
            {
              "heading": "Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde",
              "details": "<span class='mobile-paragraph'>Im Falle datenschutzrechtlicher Verstöße hat der Betroffene das Recht, sich bei der zuständigen Aufsichtsbehörde zu beschweren. Die zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. </span><br><span class='mobile-paragraph'>Die Liste der Datenschutzbeauftragten und deren Kontaktdaten finden Sie unter dem folgenden Link:</span> <a target='blank' href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>"
            },
            {
              "heading": "Auskunft, Sperrung, Löschung",
              "details": "<span class='mobile-paragraph'>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.</span> Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden."
            },
            {
              "heading": "Widerspruch gegen Werbe-E-Mails",
              "details": "Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor."
            },
            {
              "heading": "Gesetzlich vorgeschriebener Datenschutzbeauftragter",
              "details": "Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.<br/>Konstantin Schtscherbina <br/>Weinstraße 5 <br/>67146 Deidesheim <br/>Telefon: +49 (0) 6234 809 2676 <br/>E-Mail: datenschutz@skiy31.de"
            },
          ]
        },
        {
          "heading": "Plugins and Tools",
          "data": [
            {
              "heading": "Google Maps",
              "details": "<span class='mobile-paragraph'>Diese Website nutzt den Kartendienst von Google Maps über eine API. Der Anbieter ist die Google Inc. mit Sitz in 1600 Amphitheater Parkway, Mountain View, CA 94043, USA. Zur Nutzung des Kartendienstes wird Ihre IP-Adresse gespeichert und an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung. </span> <span class='mobile-paragraph'> Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und zur Erleichterung des Auffindens der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse i.S.d. Art. 6 Abs. 1 lit. f GDPR dar. Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung oder bei Google:</span><br/> <a target='blank' href='https://www.google.de/intl/de/policies/privacy/'>https://www.google.de/intl/de/policies/privacy/</a>"
            },
            {
              "heading": "YouTube",
              "details": "<span class='mobile-paragraph'>Wir haben für YouTube-Videos, die auf der Website angezeigt werden, den \"datenschutzfreundlichen Modus\" aktiviert. Dies bedeutet, dass YouTube keine Cookies platziert oder das Sehverhalten zu Werbezwecken verfolgt. Die Integration bettet keine YouTube-Videos ein, die nicht Teil unserer Inhalte sind. Wir haben bestimmte Funktionen auf YouTube deaktiviert (sogenannte \"rel\"-Funktionalität), so dass nur unsere Inhalte abgespielt werden. Weitere Informationen finden Sie in den </span><a href='http://www.google.com/policies/privacy/partners' target='_blank'>Datenschutzbestimmungen von Google.</a>"
            }
          ]
        },
      ]
    },
    {
      "heading": "Cookie-Politik",
      "data": [
        {
          "heading": "",
          "data": [
            {
              "heading": "Über diese Cookie-Richtlinie.",
              "details": "<span class='mobile-paragraph'>Diese Cookie-Richtlinie erklärt, was Cookies sind und wie wir sie verwenden. Sie sollten diese Richtlinie lesen, um zu verstehen, was Cookies sind, wie wir sie verwenden, welche Arten von Cookies wir verwenden, d.h. welche Informationen wir mit Hilfe von Cookies sammeln und wie diese Informationen verwendet werden, und wie Sie die Cookie-Einstellungen steuern können. Weitere Informationen darüber, wie wir Ihre persönlichen Daten verwenden, speichern und sicher aufbewahren, finden Sie in unserer Datenschutzrichtlinie.</span><br/>Sie können Ihre Einwilligung in der Cookie-Erklärung auf unserer Website jederzeit ändern oder widerrufen. Weitere Informationen darüber, wer wir sind, wie Sie uns kontaktieren können und wie wir personenbezogene Daten verarbeiten, finden Sie in unserer Datenschutzrichtlinie. Ihr Einverständnis gilt für die folgenden Domains: skiy31.de ."
            },
            {
              "heading": "Was sind Cookies?",
              "details": "<span class='mobile-paragraph'>Cookies sind kleine Textdateien, die dazu dienen, kleine Informationen zu speichern. Die Cookies werden auf Ihrem Gerät gespeichert, wenn die Website über Ihren Browser geladen wird.</span> Diese Cookies helfen uns, die Website ordnungsgemäß funktionieren zu lassen, die Website sicherer zu machen, eine bessere Benutzererfahrung zu bieten und zu verstehen, wie die Website funktioniert und zu analysieren, was funktioniert und wo sie verbessert werden muss."
            },
            {
              "heading": "Wie verwenden wir Cookies?",
              "details": "<span class='mobile-paragraph'>Wie die meisten Online-Dienste verwendet auch unsere Website Cookies von Erstanbietern und Drittanbietern für eine Reihe von Zwecken. Die Erstanbieter-Cookies sind meist notwendig, damit die Website richtig funktioniert, und sie erfassen keine personenbezogenen Daten.</span><br/>Die Cookies von Drittanbietern, die auf unseren Websites verwendet werden, dienen hauptsächlich dazu, zu verstehen, wie die Website funktioniert, wie Sie mit unserer Website interagieren, unsere Dienste sicher zu halten, Ihnen für Sie relevante Werbung anzubieten und Ihnen insgesamt eine bessere und verbesserte Benutzererfahrung zu bieten und Ihre zukünftigen Interaktionen mit unserer Website zu beschleunigen."
            },
            {
              "heading": "Welche Arten von Cookies verwenden wir?",
              "details": "",
              "data": [
                {
                  "heading": "Erforderlich",
                  "details": "Notwendige Cookies sind für das ordnungsgemäße Funktionieren der Website unbedingt erforderlich. Zu dieser Kategorie gehören nur Cookies, die grundlegende Funktionalitäten und Sicherheitsmerkmale der Website gewährleisten. Diese Cookies speichern keine persönlichen Informationen.",
                  "tabledata": [
                    {
                      "heading": "viewed_cookie_policy",
                      "details": "Das Cookie wird vom GDPR Cookie Consent Plugin gesetzt und wird verwendet, um zu speichern, ob der Nutzer der Verwendung von Cookies zugestimmt hat oder nicht. Es speichert keine persönlichen Daten."
                    },
                    {
                      "heading": "cookielawinfo-checkbox-necessary",
                      "details": `Dieses Cookie wird vom GDPR Cookie Consent Plugin gesetzt. Das Cookie wird verwendet, um die Zustimmung des Nutzers für die Cookies der Kategorie "Notwendig" zu speichern.`
                    },
                    {
                      "heading": "cookielawinfo-checkbox-non-necessary",
                      "details": `Dieses Cookie wird vom GDPR Cookie Consent Plugin gesetzt. Das Cookie wird verwendet, um die Zustimmung des Nutzers für die Cookies der Kategorie "Nicht notwendig" zu speichern.`
                    }
                  ]
                },
                {
                  "heading": "Analytik",
                  "details": "Analytische Cookies werden verwendet, um zu verstehen, wie Besucher mit der Website interagieren. Diese Cookies helfen dabei, Informationen über die Anzahl der Besucher, die Absprungrate, die Verkehrsquelle usw. zu erhalten.",
                  "tabledata": [
                    {
                      "heading": "_gat_gtag_UA_171995910_1",
                      "details": "Google verwendet dieses Cookie, um Nutzer zu unterscheiden."
                    },
                    {
                      "heading": "_ga",
                      "details": `Dieses Cookie wird von Google Analytics installiert. Das Cookie wird verwendet, um Besucher-, Sitzungs- und Camapign-Daten zu berechnen und die Nutzung der Website für den Analysebericht der Website zu verfolgen. Die Cookies speichern Informationen anonym und weisen eine zufällig generierte Nummer zu, um eindeutige Besucher zu identifizieren.`
                    },
                    {
                      "heading": "_gid",
                      "details": `Dieses Cookie wird von Google Analytics installiert. Der Cookie wird verwendet, um Informationen darüber zu speichern, wie Besucher eine Website nutzen, und hilft bei der Erstellung eines Analyseberichts über die Leistung der Website. Zu den gesammelten Daten gehören die Anzahl der Besucher, die Quelle, aus der sie kommen, und die besuchten Seiten in anonymer Form.`
                    }
                  ]
                },
                {
                  "heading": "Anzeige",
                  "details": "Werbe-Cookies werden verwendet, um Besuchern relevante Werbung und Marketing-Kampagnen anzubieten. Diese Cookies verfolgen die Besucher auf verschiedenen Websites und sammeln Informationen, um maßgeschneiderte Werbung bereitzustellen. <br/>In der folgenden Liste sind die auf unserer Website verwendeten Cookies aufgeführt.",
                                  "tabledata": [
                      {
                        "heading": "NID",
                      "details": "Dieses Cookie wird für das Benutzerprofil verwendet, um personalisierte Werbung anzuzeigen."
                    }
                  ]
                
                              },
                          ]
    
                      },
                      {
    "heading": "Wie kann ich die Cookie-Einstellungen kontrollieren?",
  "className": "control-cookie-pref",
    "details": "Sie können Ihre Cookie-Einstellungen verwalten, indem Sie auf die Schaltfläche \"Einstellungen\" klicken und die Cookie-Kategorien auf dem Popup-Fenster entsprechend Ihren Präferenzen aktivieren oder deaktivieren.<br/>Sollten Sie sich entscheiden, Ihre Einstellungen im Laufe Ihrer Browsing-Sitzung zu ändern, können Sie auf den unten stehenden Link klicken:<br/>Ihr aktueller Status: Zustimmung akzeptiert. Alle Cookies zulassen (Nötig, Analyse, Werbung). Verwalten Sie Ihr Einverständnis.<br/>Dadurch wird der Hinweis auf die Einwilligung erneut angezeigt, so dass Sie Ihre Einstellungen ändern oder Ihre Einwilligung vollständig widerrufen können.<br/>Darüber hinaus bieten die verschiedenen Browser unterschiedliche Methoden zum Blockieren und Löschen von Cookies, die von Websites verwendet werden. Sie können die Einstellungen Ihres Browsers ändern, um die Cookies zu blockieren/löschen. Weitere Informationen über die Verwaltung und Löschung von Cookies finden Sie unter wikipedia.org, www.allaboutcookies.org"
                      } 
                  ]
              }
          ]
      }
  ]
}

const LegalNoticeDetailsEN = {
    "heading": "Legal Notice",
  "data": [
      {
        "heading": "",
      "data": [
          {
            "heading": "Company Information",
          "details": "Rights concerned § 5 Telemedia Act (TMG)<br/>SKIY31 GmbH <br/>Weinstraße 5 <br/>D-67146 Deidesheim<br/>General Manager: Konstantin Shcherbina<br/>Phone: +49 (0) 6234 809 26 76 <br/>Telefax: +49 (0) 6234 945 73 20 <br/>E-Mail: info@skiy31.de<br/>Company registration: Mannheim <br/>Register court: District court Mannheim <br/>Register number: HRB 736533 <br/>UstIdNr: DE330562296"
        }, 
                {
        "heading": "Data protection officer",
      "details": "Konstantin Shcherbina <br/>Phone: +49 (0) 6234 809 26 76 <br/>E-Mail: datenschutz@skiy31.de"
    }, 
                {
      "heading": "Dispute settlement",
    "details": "We are not willing or obliged to participate in dispute resolution proceedings with a consumer arbitration board."
  },
                {
    "heading": "Liability for content",
  "details": "<span class='mobile-paragraph'>As a service provider, we are responsible for our content on these pages following general laws under Section 7 (1) TMG. According to §§ 8 to 10 TMG, we as a service provider are not obliged to monitor transmitted or stored third-party information or to search for circumstances that indicate illegal activity.</span><br/>Obligations to remove or block the use of information according to general laws remain unaffected. However, liability in this regard is only possible from the time we become aware of a specific legal violation. As soon as we become aware of such violations, we will remove this content immediately."
                }, 
                {
    "heading": "Liability for links",
  "details": "<span class='mobile-paragraph'>Our offer contains links to external websites of third parties, the content of which we do not influence. For this reason, we cannot accept any liability for this external content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. No illegal content was discernible at the time the link was created.</span><br/>A permanent control of the content of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of legal violations, we will remove such links immediately."
                }, 
                {
    "heading": "Copyright",
  "details": "<span class='mobile-paragraph'>The content and works on these pages created by the site operator are subject to German copyright law. Duplication, editing, distribution and any kind of use beyond the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this page are only permitted for private, non-commercial use.</span><br/><span class='mobile-paragraph'>As far as the operator did not create the content on this page, the copyrights of third parties are respected. In particular, the contents of third parties are marked as such. Should you nevertheless become aware of a copyright infringement, please let us know. As soon as we become aware of legal violations, we will remove such content immediately.</span><br/><span class='mobile-paragraph'>The copyright for published objects created by SKIY31 remains solely with SKIY31. Duplication or use of such graphics, sound documents, video sequences and texts in other electronic or printed publications is not permitted without express consent.</span><br/>All brand names, trademarks, and designations used are registered brands and trademarks and are the property of their respective and legitimate owners."
                } 
            ]
        }
    ]
}
const LegalNoticeDetailsDE = {
  "heading": "Impressum",
  "data": [
      {
        "heading": "",
      "data": [
          {
            "heading": "Informationen zum Unternehmen",
          "details": "Betroffene Rechte § 5 Telemediengesetz (TMG)<br/>SKIY31 GmbH <br/>Weinstraße 5 <br/>D-67146 Deidesheim<br/>Geschäftsführer: Konstantin Shcherbina<br/>Telefon: +49 (0) 6234 809 26 76 <br/>Telefax: +49 (0) 6234 945 73 20 <br/>E-Mail: info@skiy31.de<br/>Firmensitz: Mannheim <br/>Registergericht: Amtsgericht Mannheim <br/>Registernummer: HRB 736533 <br/>UstIdNr: DE330562296"
        }, 
              {
        "heading": "Datenschutzbeauftragter",
      "details": "Konstantin Schtscherbina<br/>Telefon: +49 (0) 6234 809 26 76 <br/>E-Mail: datenschutz@skiy31.de"
    }, 
              {
      "heading": "Streitschlichtung",
    "details": "Wir sind nicht bereit oder verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen."
  },
              {
    "heading": "Haftung für Inhalte",
  "details": "<span class='mobile-paragraph'>Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</span><br/>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen."
              }, 
              {
    "heading": "Haftung für Links",
  "details": "<span class='mobile-paragraph'>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Aus diesem Grund können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</span><br/>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen."
              }, 
              {
    "heading": "Urheberrecht",
  "details": "<span class='mobile-paragraph'>Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</span><br/><span class='mobile-paragraph'>Soweit der Betreiber die Inhalte auf dieser Seite nicht selbst erstellt hat, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</span><br/><span class='mobile-paragraph'>Das Copyright für veröffentlichte, von SKIY31 selbst erstellte Objekte bleibt allein bei SKIY31. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung nicht gestattet.</span><br/>Alle verwendeten Markennamen, Warenzeichen und Bezeichnungen sind eingetragene Warenzeichen und Eigentum der jeweiligen rechtmäßigen Inhaber."
              } 
          ]
      }
  ]
}
export {
    Constants, ActionTypesList, ContactCTA, DefaultCTA, DefaultCTAUpdated, DefaultCTAUpdatedV2, MainCTA, CaseStudyCTA, CaseStudyCTAUpdated, urlCreate, apiURls, footers,
  navToService, mapPosLocation, mapYears, mapLevel, footerServicesMenu,
  ActiveTypesMapping, isValidEmail, isValidFullName, isValidPhoneNumber, isNumberOnly, isValid, isValidLnAndXing,
  servicesCommonConfig,
  screenSize, TiltDefaultOptions, PrivacyPolicyDetailsEN,PrivacyPolicyDetailsDE, LegalNoticeDetailsEN, LegalNoticeDetailsDE,
  scrollHeaderHeight, 
  getRoutePath, getPageURl, getPathLocale, checkLanguage,
}; 